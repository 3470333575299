import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import classNames from "classnames";
import { ErrorMessage, Field, Form, Formik } from "formik";
import Button from "../../../components/common/Button";
import FormLabel from "../../../components/common/FormLabel";
import AuthLayout from "../../../layouts/AuthLayout";
import AuthLayoutHeader from "../../../layouts/AuthLayout/Header";
import { AuthService, ToastService } from "../../../services";
import { validators } from "../../../utils/helpers";

const LoginViaPhone: FC = () => {
	const { t } = useTranslation();
	const history = useHistory();

	const validationError = {
		required: t('This field is required'),
		invalidPhone: t('Please input valid phone number')
	};

	const formSchema = Yup.object().shape({
		phone: Yup.string().matches(validators.phone, validationError.invalidPhone)
			.typeError(validationError.invalidPhone)
			.required(validationError.required),
	});

	const initialFormValues = {
		phone: '',
	};

	const onSubmit = (form: { phone: string }) => {
		const phone = `+${form.phone.replace(/\D/g, '')}`;
		AuthService.loginCustomerViaPhone(phone).then(() => {
			history.push(`/verify-code?phone=${phone}`);
		}).catch((err) => {
			ToastService.showHttpError(err, t('Login failed') as string);
		});
	};

	return (
		<AuthLayout hasPadding={true}>
			<div className='mb-7'>
				<AuthLayoutHeader title={t('Login')} />
			</div>

			<Formik
				initialValues={initialFormValues}
				validationSchema={formSchema}
				onSubmit={onSubmit}
			>
				{(formik) => {
					const { errors, touched, isValid, dirty } = formik;

					return (
						<Form className="formik-form max-w-[670px] m-auto">
							<div className='bg-white rounded px-6 py-5 lg:px-16 lg:py-10'>
								<div className={classNames(
									'form-field full-width',
									errors.phone && touched.phone && 'is-invalid',
								)}>
									<FormLabel label={t('What is your number?')} />
									<Field id="phone" name="phone" />
									<ErrorMessage name="phone" component="div" className="invalid-feedback" />
								</div>
								<div className='flex items-center justify-end mt-8'>
									<Button
										type="submit"
										theme="primary"
										rounded="full"
										disabled={!dirty || !isValid}
									>
										{t('Send verification code')}
									</Button>
								</div>
							</div>
						</Form>
					);
				}}
			</Formik>
		</AuthLayout>
	);
};

export default LoginViaPhone;
