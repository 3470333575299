import React, { FC } from "react";

export const HomeIcon: FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_578_479)">
        <path
          d="M12 5.69L17 10.19V18H15V12L9 12L9 18H7L7 10.19L12 5.69ZM12 3L2 12H5L5 20H11V14H13V20L19 20L19 12H22L12 3Z"
          fill="#2165B7"
        />
      </g>
      <defs>
        <clipPath id="clip0_578_479">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
