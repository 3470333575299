import { Switch, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import SpinnerContainer from "./components/common/SpinnerContainer";
import LanguageSelection from "./pages/LanguageSelection";
import AuthHome from "./pages/Auth/AuthHome";
import Register from "./pages/Auth/Register";
import LoginViaPhone from "./pages/Auth/LoginViaPhone";
import LoginViaEmail from "./pages/Auth/LoginViaEmail";
import VerifyCode from "./pages/Auth/VerifyCode";

function App() {
	return (
		<>
			<Switch>
				<Route exact path="/">
					<LanguageSelection />
				</Route>
				<Route path="/login">
					<AuthHome />
				</Route>
				<Route path="/register">
					<Register />
				</Route>
				<Route path="/login-via-phone">
					<LoginViaPhone />
				</Route>
				<Route path="/login-via-email">
					<LoginViaEmail />
				</Route>
				<Route path="/verify-code">
					<VerifyCode />
				</Route>
			</Switch>

			<ToastContainer />
			<SpinnerContainer />
		</>
	);
}

export default App;
