import React, { FC, ReactElement } from 'react';

interface FormLabelProps {
  label: string | ReactElement | null;
  htmlFor?: string;
}

const FormLabel: FC<FormLabelProps> = ({
  label,
  htmlFor= "",
}) => (
  <label htmlFor={htmlFor} className="font-semibold text-base mb-2 inline-block">
    {label}
  </label>
);

export default FormLabel;
