import { configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";
import authReducer, {IAuthState } from "./reducers/auth.reducer";
import localeReducer, {ILocaleState } from "./reducers/locale.reducer";
import spinnerReducer, {ISpinnerState } from "./reducers/spinner.reducer";

export const store = configureStore({
	reducer: {
		auth: authReducer,
		locale: localeReducer,
		spinner: spinnerReducer,
	},
	middleware: [logger] as const,
});

export type IRootState = {
	auth: IAuthState,
	locale: ILocaleState,
	spinner: ISpinnerState,
};

export type AppDispatch = typeof store.dispatch;
