import { FC } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { ArrowForward } from "../../icons";

type IconLinkProps = {
	className?: string;
	name: string;
	image: string;
	url?: string | UrlObjectType;
	textClass?: string;
};

type UrlObjectType = {
	pathname: string;
	state?: {
		image: string;
	};
};

const IconLinkButton: FC<IconLinkProps> = ({
	className,
	name,
	image,
	url = "#",
	textClass = "",
}) => {
	return (
		<Link
			to={url}
			className={classNames(
				'bg-white rounded border border-secondary py-4 md:py-6 xl:py-8 px-4 xl:px-10 flex items-center group transition-all',
				'hover:bg-blue-900 hover:rounded-3xl',
				className,
			)}
		>
			<img className='w-[36px] sm:w-[40px] md:w-auto md:max-w-[80px] xl:max-w-[120px]' src={image} alt='' />
			<p className={`text-2xl group-hover:text-white ml-14 mr-4 ${textClass}`}>
				{name}
			</p>
			<div
				className='w-[36px] sm:w-[40px] md:w-[48px] h-[36px] sm:h-[40px] md:h-[48px] flex-shrink-0 bg-white border border-secondary rounded-full ml-auto flex items-center justify-center cursor-pointer'
			>
				<ArrowForward />
			</div>
		</Link>
	);
};

export default IconLinkButton;
