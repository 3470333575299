import React, { FC, ReactNode } from "react";

type LayoutProps = {
	children: ReactNode;
	hasPadding?: boolean;
};

const AuthLayout: FC<LayoutProps> = ({ children, hasPadding = false }) => {
	return (
		<div className={`w-full min-h-screen flex items-stretch bg-blue-100 text-blue-900 relative ${hasPadding ? 'lg:p-10 p-5' : ''}`}>
			<div className='w-full max-w-[1366px] mx-auto'>{children}</div>
		</div>
	);
};

export default AuthLayout;
