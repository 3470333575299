import { CUSTOMER_TYPE } from "../enums";

export class CustomerModel {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  type: CUSTOMER_TYPE;
  phone: string;
  vatNumber?: string;
  companyName?: string;
  country: string;
  city: string;
  street: string;
  houseNumber: string;
  postcode: string;
  createdAt: string;
  updatedAt: string;

  constructor(init?: Partial<CustomerModel>) {
    const data = {
      id: 0,
      email: '',
      firstName: '',
      lastName: '',
      type: CUSTOMER_TYPE.PRIVATE,
      phone: '',
      country: '',
      city: '',
      street: '',
      houseNumber: '',
      postcode: '',
      createdAt: '',
      updatedAt: '',
      ...init,
    };

    this.id = data.id;
    this.email = data.email;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.type = data.type;
    this.phone = data.phone;
    this.vatNumber = data.vatNumber;
    this.companyName = data.companyName;
    this.country = data.country;
    this.city = data.city;
    this.street = data.street;
    this.houseNumber = data.houseNumber;
    this.postcode = data.postcode;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
  }
}
