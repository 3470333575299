import React, { ChangeEvent, FC, useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import classNames from "classnames";
import { debounce } from "lodash";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Button from "../../../components/common/Button";
import CountrySelect from "../../../components/common/CountrySelect";
import SelectableOption from "../../../components/common/SelectableOption";
import AuthSuccess from "../../../components/common/AuthSuccess";
import FormLabel from "../../../components/common/FormLabel";
import FormCheckbox from "../../../components/common/FormCheckbox";
import AuthLayoutHeader from "../../../layouts/AuthLayout/Header";
import AuthLayout from "../../../layouts/AuthLayout";
import { useAppDispatch } from "../../../redux/hooks";
import { setToken } from "../../../redux/reducers/auth.reducer";
import { CUSTOMER_TYPE } from "../../../resources/enums";
import {
  ICustomerRegisterForm,
  IVatNumberInfo,
} from "../../../resources/interfaces";
import { AuthService, ToastService } from "../../../services";
import { parseAddress } from "../../../utils/helpers";

type RegisterForm = Partial<ICustomerRegisterForm> & { regionCode: string };

const Register: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [acceptPolicy, setAcceptPolicy] = useState(false);
  const [accessToken, setAccessToken] = useState("");
  const [vatNumberInfo, setVatNumberInfo] = useState<IVatNumberInfo>();

  const validationError = {
    required: t("This field is required"),
    invalidEmail: t("Please input valid email"),
    invalidPhone: t("Please input valid phone number"),
  };

  const formSchema = Yup.object().shape({
    type: Yup.string().required(validationError.required),
    firstName: Yup.string().required(validationError.required),
    lastName: Yup.string().required(validationError.required),
    email: Yup.string()
      .email(validationError.invalidEmail)
      .typeError(validationError.invalidEmail)
      .required(validationError.required),
    regionCode: Yup.string().required(validationError.required),
    phone: Yup.string()
      .matches(/^\d{4,10}$/, validationError.invalidPhone)
      .typeError(validationError.invalidPhone)
      .required(validationError.required),
    vatNumber: Yup.string().test(
      "is-company",
      validationError.required,
      (value, context) =>
        Boolean(value || context.parent.type === CUSTOMER_TYPE.PRIVATE)
    ),
    companyName: Yup.string().test(
      "is-company",
      validationError.required,
      (value, context) =>
        Boolean(value || context.parent.type === CUSTOMER_TYPE.PRIVATE)
    ),
    country: Yup.string().required(validationError.required),
    city: Yup.string().required(validationError.required),
    street: Yup.string().required(validationError.required),
    houseNumber: Yup.string().required(validationError.required),
    postcode: Yup.string().required(validationError.required),
  });

  const initialFormValues = {
    type: undefined,
    firstName: "",
    lastName: "",
    email: "",
    regionCode: "",
    phone: "",
    vatNumber: "",
    companyName: "",
    country: "BE",
    city: "",
    street: "",
    houseNumber: "",
    postcode: "",
  };

  const customerTypeOptions = [
    { text: t("Private"), value: CUSTOMER_TYPE.PRIVATE },
    { text: t("Company"), value: CUSTOMER_TYPE.COMPANY },
  ];

  const validateVatNumber = useCallback(
    async (
      vatNumber: string,
      callback: (result: IVatNumberInfo | null) => void
    ) => {
      if (vatNumber.length < 10) {
        callback(null);
        return;
      }

      try {
        const result = await AuthService.validateVatNumber(vatNumber);
        if (result.valid !== "true") {
          callback(null);
          return;
        } else {
          callback(result);
        }
      } catch {
        callback(null);
      }
    },
    []
  );

  const debouncedValidateVatNumber = debounce(validateVatNumber, 500);

  const onSubmit = async (form: RegisterForm) => {
    const { regionCode, ...data } = form;
    const phone = `+${regionCode}${form.phone}`;
    AuthService.registerCustomer({
      ...data,
      phone,
    } as ICustomerRegisterForm)
      .then((res) => {
        setAccessToken(res.token);
        dispatch(setToken(accessToken));
      })
      .catch((err) => {
        ToastService.showHttpError(err, t("Register failed") as string);
      });
  };

  return (
    <AuthLayout hasPadding={true}>
      {!accessToken ? (
        <>
          <div className="mb-7">
            <AuthLayoutHeader title="Registreren" />
          </div>

          <Formik
            initialValues={initialFormValues}
            validationSchema={formSchema}
            onSubmit={onSubmit}
          >
            {(formik) => {
              const {
                errors,
                touched,
                isValid,
                values,
                setFieldValue,
                setFieldError,
              } = formik;

              const isCompanyUser = values.type === CUSTOMER_TYPE.COMPANY;
              const shouldAddressFields =
                !isCompanyUser || (values.vatNumber && vatNumberInfo);
              const isFormValid =
                isValid && acceptPolicy && (!isCompanyUser || vatNumberInfo);

              return (
                <Form className="formik-form max-w-[670px] m-auto">
                  <div className="bg-white rounded px-6 py-5 lg:px-16 lg:py-10">
                    <FormLabel label={t("I register as")} />
                    <div className="flex flex-wrap gap-x-2">
                      {customerTypeOptions.map((option) => (
                        <SelectableOption
                          key={option.value}
                          label={option.text}
                          selected={values.type === option.value}
                          handleSelect={() =>
                            setFieldValue("type", option.value)
                          }
                        />
                      ))}
                    </div>
                  </div>

                  {values.type && (
                    <div className="bg-white rounded px-6 py-5 lg:px-16 lg:py-10 mt-6">
                      <div className="grid grid-cols-12 gap-x-4 gap-y-6">
                        <div
                          className={classNames(
                            "form-field col-span-12 full-width",
                            errors.firstName &&
                              touched.firstName &&
                              "is-invalid"
                          )}
                        >
                          <FormLabel
                            label={t("First name")}
                            htmlFor="firstName"
                          />
                          <Field id="firstName" name="firstName" />
                          <ErrorMessage
                            name="firstName"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>

                        <div
                          className={classNames(
                            "form-field col-span-12 full-width",
                            errors.lastName && touched.lastName && "is-invalid"
                          )}
                        >
                          <FormLabel
                            label={t("Last name")}
                            htmlFor="lastName"
                          />
                          <Field id="lastName" name="lastName" />
                          <ErrorMessage
                            name="lastName"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>

                        <div
                          className={classNames(
                            "form-field col-span-12 full-width",
                            errors.email && touched.email && "is-invalid"
                          )}
                        >
                          <FormLabel label={t("E-mail")} htmlFor="email" />
                          <Field id="email" name="email" />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>

                        <div className="col-span-12">
                          <FormLabel label={t("GSM or Phone number")} />
                          <div className="grid grid-cols-12 gap-x-4">
                            <div className="col-span-5 sm:col-span-4">
                              <CountrySelect
                                type="code"
                                onChange={(value) =>
                                  setFieldValue("regionCode", value.regionCode)
                                }
                              />
                            </div>
                            <div
                              className={classNames(
                                "form-field col-span-7 sm:col-span-8 full-width",
                                errors.phone && touched.phone && "is-invalid"
                              )}
                            >
                              <Field id="phone" name="phone" />
                              <ErrorMessage
                                name="phone"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </div>
                        </div>

                        {values.type === CUSTOMER_TYPE.COMPANY && (
                          <>
                            <div
                              className={classNames(
                                "form-field col-span-12 full-width",
                                (errors.vatNumber || !vatNumberInfo) &&
                                  touched.vatNumber &&
                                  "is-invalid"
                              )}
                            >
                              <FormLabel
                                label={t("VAT number")}
                                htmlFor="vatNumber"
                              />
                              <Field
                                id="vatNumber"
                                name="vatNumber"
                                onChange={(
                                  e: ChangeEvent<HTMLInputElement>
                                ) => {
                                  setFieldValue("vatNumber", e.target.value);
                                  if (e.target.value) {
                                    debouncedValidateVatNumber(
                                      e.target.value,
                                      (result) => {
                                        if (result) {
                                          setVatNumberInfo(result);
                                          setFieldError("vatNumber", undefined);

                                          const address = parseAddress(
                                            result.traderAddress
                                          );
                                          setFieldValue(
                                            "companyName",
                                            result.traderName
                                          );
                                          setFieldValue(
                                            "street",
                                            address.street
                                          );
                                          setFieldValue(
                                            "houseNumber",
                                            address.houseNumber
                                          );
                                          setFieldValue(
                                            "postcode",
                                            address.postcode
                                          );
                                          setFieldValue("city", address.city);
                                          setFieldValue(
                                            "country",
                                            result.countryCode
                                          );
                                        } else {
                                          setVatNumberInfo(undefined);
                                          setFieldError(
                                            "vatNumber",
                                            t("Validating VAT number failed") ||
                                              "Validating VAT number failed"
                                          );
                                        }
                                      }
                                    );
                                  }
                                }}
                              />
                              <ErrorMessage
                                name="vatNumber"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>

                            {shouldAddressFields && (
                              <div
                                className={classNames(
                                  "form-field col-span-12 full-width",
                                  errors.companyName &&
                                    touched.companyName &&
                                    "is-invalid"
                                )}
                              >
                                <FormLabel
                                  label={t("Company name")}
                                  htmlFor="companyName"
                                />
                                <Field
                                  id="companyName"
                                  name="companyName"
                                  disabled={isCompanyUser}
                                />
                                <ErrorMessage
                                  name="companyName"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                            )}
                          </>
                        )}

                        {shouldAddressFields && (
                          <div
                            className={classNames(
                              "form-field col-span-8 sm:col-span-9 full-width",
                              errors.street && touched.street && "is-invalid"
                            )}
                          >
                            <FormLabel label={t("Street")} htmlFor="street" />
                            <Field
                              id="street"
                              name="street"
                              disabled={isCompanyUser}
                            />
                            <ErrorMessage
                              name="street"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        )}

                        {shouldAddressFields && (
                          <div
                            className={classNames(
                              "form-field col-span-4 sm:col-span-3 full-width",
                              errors.houseNumber &&
                                touched.houseNumber &&
                                "is-invalid"
                            )}
                          >
                            <FormLabel
                              label={t("Number")}
                              htmlFor="houseNumber"
                            />
                            <Field
                              id="houseNumber"
                              name="houseNumber"
                              disabled={isCompanyUser}
                            />
                            <ErrorMessage
                              name="houseNumber"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        )}

                        {shouldAddressFields && (
                          <div
                            className={classNames(
                              "form-field col-span-4 full-width",
                              errors.postcode &&
                                touched.postcode &&
                                "is-invalid"
                            )}
                          >
                            <FormLabel
                              label={t("Postal code")}
                              htmlFor="postcode"
                            />
                            <Field
                              id="postcode"
                              name="postcode"
                              disabled={isCompanyUser}
                            />
                            <ErrorMessage
                              name="postcode"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        )}

                        {shouldAddressFields && (
                          <div
                            className={classNames(
                              "form-field col-span-8 full-width",
                              errors.city && touched.city && "is-invalid"
                            )}
                          >
                            <FormLabel label={t("City")} htmlFor="city" />
                            <Field
                              id="city"
                              name="city"
                              disabled={isCompanyUser}
                            />
                            <ErrorMessage
                              name="city"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        )}

                        {shouldAddressFields && (
                          <div
                            className={classNames(
                              "form-field col-span-12 full-width",
                              errors.country && touched.country && "is-invalid"
                            )}
                          >
                            <FormLabel label={t("Country")} htmlFor="country" />
                            <CountrySelect
                              type="country"
                              country={values.country}
                              disabled={isCompanyUser}
                              onChange={(value) =>
                                setFieldValue("country", value.countryCode)
                              }
                            />
                            <ErrorMessage
                              name="city"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        )}

                        <div className="col-span-12">
                          <FormCheckbox
                            id="agreement"
                            checked={acceptPolicy}
                            label={
                              <p>
                                {t("I agree to the")}
                                <Link to="/toc" className="text-blue ml-1">
                                  {t("terms of use")}
                                </Link>
                                .
                              </p>
                            }
                            onChange={setAcceptPolicy}
                          />
                        </div>
                      </div>

                      <div className="flex items-center justify-end mt-11">
                        <Button
                          type="submit"
                          theme="primary"
                          rounded="full"
                          disabled={!isFormValid}
                        >
                          {t("Create an account")}
                        </Button>
                      </div>
                    </div>
                  )}
                </Form>
              );
            }}
          </Formik>
        </>
      ) : (
        <AuthSuccess
          title={t(
            "Your account has been successfully registered and logged in automatically"
          )}
          subtitle={`${t(
            "We have sent an email with your unique customer code"
          )} ${t("An employee will help you further.")}`}
        />
      )}
    </AuthLayout>
  );
};

export default Register;
