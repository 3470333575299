import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import IconLinkButton from "../../../components/IconLinkButton";
import AuthLayout from "../../../layouts/AuthLayout";
import AuthLayoutHeader from "../../../layouts/AuthLayout/Header";

const AuthHome: FC = () => {
	const { t } = useTranslation();

	return (
		<AuthLayout hasPadding={true}>
			<div className='mb-7'>
				<AuthLayoutHeader
					title={t('Make your choice')}
				/>
			</div>
			<div className='w-full lg:flex items-stretch gap-x-6 min-h-[calc(100vh-156px)]'>
				<div className='lg:w-1/2 bg-blue-900 rounded lg:p-20 p-5'>
					<h1 className='mt-[40px] lg:mt-[140px] md:mb-[104px] mb-[52px] text-center font-bold lg:text-5xl text-4xl text-white'>
						{t('Register as a new customer')}
					</h1>
					<IconLinkButton
						image="/assets/images/edit-icon.svg"
						name={t('Register me')}
						textClass='!ml-6 !text-xl'
						url="/register"
					/>
				</div>
				<div className='lg:w-1/2 bg-white rounded lg:p-20 p-5 mt-8 lg:mt-0'>
					<h1 className='lg:mt-[140px] mt-[40px] md:mb-[104px] mb-[52px] text-center font-bold lg:text-5xl text-4xl text-blue-900'>
						{t('Sign in with your account')}
					</h1>
					<div className='flex flex-col gap-y-4'>
						<IconLinkButton
							image="/assets/images/mobile-icon.svg"
							name={t('Login by phone')}
							textClass='!ml-6 !text-xl'
							url="/login-via-phone"
						/>
						<IconLinkButton
							image="/assets/images/mention-icon.svg"
							name={t('Login via e-mail')}
							textClass='!ml-6 !text-xl'
							url="/login-via-email"
						/>
					</div>
				</div>
			</div>
		</AuthLayout>
	);
};

export default AuthHome;
