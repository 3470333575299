import React, { FC, useEffect, useState } from "react";
import qs from "qs";
import OtpInput from "react18-input-otp";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useLocation } from "react-router-dom";
import AuthSuccess from "../../../components/common/AuthSuccess";
import FormLabel from "../../../components/common/FormLabel";
import AuthLayout from "../../../layouts/AuthLayout";
import { useAppDispatch } from "../../../redux/hooks";
import { setToken } from "../../../redux/reducers/auth.reducer";
import { AuthService, ToastService } from "../../../services";

const VerifyCode: FC = () => {
	const { t } = useTranslation();
	const history = useHistory();
	const location = useLocation();
	const dispatch = useAppDispatch();
	const [code, setCode] = useState('');
	const [phone, setPhone] = useState('');
	const [email, setEmail] = useState('');
	const [accessToken, setAccessToken] = useState('');

	useEffect(() => {
		const query = qs.parse(location.search.slice(1));
		if (!query.phone && !query.email) {
			history.goBack();
		} else if (query.phone) {
			setPhone(`+${query.phone.toString().replace(/\D/g, '')}`);
		} else {
			setEmail(query.email as string);
		}
	}, [history, location]);

	const onResend = () => {
		AuthService.resendVerificationCode({ email, phone })
			.then(() => {
				ToastService.success('Verification code has been sent successfully');
			})
			.catch((err) => {
				ToastService.showHttpError(err, 'Resending verification code failed');
			});
	};

	const onSubmit = () => {
		AuthService.verifyCode({ email, phone, verificationCode: code })
			.then(({ token }) => {
				setAccessToken(token);
				dispatch(setToken(accessToken));
			})
			.catch((err) => {
				ToastService.showHttpError(err, 'Verify code failed');
			});
	};

	const type = email ? 'email' : 'phone';
	const descriptionHtml = t('A verification code has been sent to ADDRESS. Please enter the code below.', { address: email || phone });

	return (
		<AuthLayout hasPadding={true}>
			{!accessToken ? (
				<div className="max-w-[850px] bg-white rounded m-auto">
					<div className="border-b border-light">
						<div className='lg:py-20 py-8 lg:px-36 px-6'>
							<h1 className='mb-6 lg:text-5xl text-3xl font-bold text-center'>
								{t(`Check your ${type}`)}
							</h1>
							<div className="w-full px-5">
								<p
									className='text-xl mb-10 text-center success-text'
									dangerouslySetInnerHTML={{ __html: descriptionHtml }}
								/>
								<div className='flex justify-center'>
									<div>
										<FormLabel label={t('Verification code')} htmlFor="otpInput" />
										<OtpInput
											id="otpInput"
											containerStyle='w-full d-flex justify-center gap-x-2'
											inputStyle='!w-[64px] sm:!w-[88px] md:!w-[124px] h-[50px] sm:h-[72px] md:h-[88px] !font-bold text-3xl text-center bg-blue-100 border border-secondary rounded focus:outline-0 py-3 px-5 font-normal text-base otp-input'
											value={code}
											numInputs={4}
											onChange={(enteredOtp: string) => setCode(enteredOtp)}
											onSubmit={onSubmit}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className='lg:py-20 py-8 lg:px-36 px-6'>
						<div className='mb-10 px-5'>
							<p className='font-bold mb-2'>
								{t('Haven\'t received the code yet?')}
							</p>
							<p className="mb-1">{t('This may take a few moments.')}</p>
							<span className="text-blue font-semibold cursor-pointer" onClick={onResend}>
								{t('Resend verification code')}
							</span>
						</div>
						<div className="px-5">
							<p className='font-bold mb-2'>{t('Still not working?')}</p>
							<p className="mb-1">{t('Try it another way.')}</p>
							{phone ? (
								<Link to="/login-via-email" className='text-blue font-semibold'>
									{t('Login via email')}
								</Link>
							) : (
								<Link to="/login-via-phone" className='text-blue font-semibold'>
									{t('Login via phone')}
								</Link>
							)}
						</div>
					</div>
				</div>
			) : (
				<AuthSuccess
					title={t('You have been successfully logged in.')}
					subtitle={t('An employee will help you further.')}
				/>
			)}
		</AuthLayout>
	);
};

export default VerifyCode;
