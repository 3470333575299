import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { CONFIG } from "../../constants";
import { CustomerModel } from "../../resources/models";

export interface IAuthState {
	token?: string | null;
	account?: CustomerModel | null;
}

const initialState: IAuthState = {
	token: undefined,
};

export const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		setToken: (state, action: PayloadAction<string | null>) => {
			state.token = action.payload;
			if (state.token) {
				localStorage.setItem(CONFIG.TOKEN_KEY, state.token);
			} else {
				localStorage.removeItem(CONFIG.TOKEN_KEY);
				state.account = null;
			}
		},
		setAccount: (state, action: PayloadAction<CustomerModel | null>) => {
			state.account = action.payload;
		},
	},
});

export const { setToken, setAccount } = authSlice.actions;

export default authSlice.reducer;
