import React, { ButtonHTMLAttributes, FC, useMemo } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

export interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  theme?: 'primary' | 'white' | 'default';
  outline?: boolean;
  fullWidth?: boolean;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  rounded?: 'sm' | 'md' | 'lg' | 'none' | 'full';
  link?: string;
}

const Button: FC<IButtonProps> = ({
  theme = 'primary',
  outline,
  fullWidth,
  size = 'md',
  rounded = 'md',
  className,
  type = 'button',
  link,
  children,
  ...buttonProps
}) => {
  const themeClass = useMemo(() => {
    switch (theme) {
      case 'primary':
        return 'bg-blue hover:bg-blue-900 disabled:!bg-blue text-white';
    }
    return '';
  }, [theme]);

  const sizeClass = useMemo(() => {
    switch (size) {
      case 'md':
        return 'text-base px-5 py-3';
    }
    return '';
  }, [size]);

  const roundClass = useMemo(() => {
    switch (rounded) {
      case 'full':
        return 'rounded-full';
    }
    return '';
  }, [rounded]);

  const button = (
    <button
      type={type}
      className={classNames(
        'font-semibold outline-0 transition-all disabled:opacity-60',
        themeClass,
        sizeClass,
        roundClass,
        fullWidth && 'w-full',
        className,
      )}
      {...buttonProps}
    >
      {children}
    </button>
  );

  if (link) {
    return (<Link className="text-decoration-none" to={link}>{button}</Link>);
  }

  return button;
};

export default Button;
