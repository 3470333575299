import React, { FC, useMemo, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import IconLinkButton from "../../../components/IconLinkButton";
import { LANGUAGES } from "../../../constants";
import { ArrowBack } from "../../../icons";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { setLocale } from "../../../redux/reducers/locale.reducer";

type HeaderProps = {
  url?: string;
  title: string;
};

const AuthLayoutHeader: FC<HeaderProps> = ({ url = "", title }) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { i18n } = useTranslation();
  const [showLanguageSelect, setShowLanguageSelect] = useState(false);
  const { locale } = useAppSelector((state) => state.locale);

  const onSelectLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    dispatch(setLocale(lang));
    setShowLanguageSelect(false);
  };

  const languageImage = useMemo(() => (
    LANGUAGES.find((language) => language.code === locale)?.image
  ), [locale]);

  return (
    <div className=" w-full flex items-center justify-between">
      <Link
        className="h-[48px] w-[48px] border border-secondary rounded-full flex items-center justify-center cursor-pointer"
        to={url}
        onClick={() => history.goBack()}
      >
        <ArrowBack />
      </Link>
      <p className="text-center font-bold text-3xl">{title}</p>
      <div
        className="w-[48px] h-[48px] bg-white border border-secondary rounded-full flex items-center justify-center cursor-pointer"
        onClick={() => setShowLanguageSelect(true)}
      >
        <img className="h-[32px] w-[32px]" src={languageImage} alt={locale} />
      </div>

      {showLanguageSelect && (
        <div className="bg-[#00000090] w-full fixed z-10 right-0 top-0">
          <div className="w-full h-full fixed z-0" onClick={() => setShowLanguageSelect(false)}></div>
            <div className={`w-[280px] sm:w-[400px] md:w-[480px] lg:w-[600px] xl:w-[820px] lg:p-10 p-5 bg-white min-h-screen flex flex-col gap-y-2 ml-auto relative z-20 h-[100vh] overflow-y-auto`}>
              {LANGUAGES.map((country) => (
                <div key={country.code} onClick={() => onSelectLanguage(country.code)}>
                  <IconLinkButton
                    name={country.name}
                    image={country.image}
                    textClass="!md:ml-14 ml-5"
                  />
                </div>
              ))}
            </div>
        </div>
      )}
    </div>
  );
};

export default AuthLayoutHeader;
