export const getCurrentLanguage = () => {
  return localStorage.getItem('i18nextLng');
};

export const parseAddress = (address: string) => {
  const [city, postcode, houseNumber, ...streets] = address.split(/,\s*|\s+/g).reverse();
  return {
    city,
    postcode,
    houseNumber,
    street: streets.reverse().join(' '),
  };
};
