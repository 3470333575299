import { FC } from "react";
import { useTranslation } from "react-i18next";
import IconLinkButton from "../../components/IconLinkButton";
import { LANGUAGES } from "../../constants";
import { AirbagLogo, MobileIcon } from "../../icons";
import AuthLayout from "../../layouts/AuthLayout";
import { useAppDispatch } from "../../redux/hooks";
import { setLocale } from "../../redux/reducers/locale.reducer";

const LanguageSelection: FC = () => {
	const dispatch = useAppDispatch();
	const { i18n } = useTranslation();

	const onSelectLanguage = (lang: string) => {
		i18n.changeLanguage(lang);
		dispatch(setLocale(lang));
	};

	return (
		<AuthLayout hasPadding={true}>
			<div className='h-full w-full flex items-center'>
				<div className='w-full md:flex items-stretch gap-x-4 m-auto'>
					<div className='md:w-2/5 flex flex-col items-center justify-evenly'>
						<div className='px-8'>
							<AirbagLogo classNames='w-[100%]' />
						</div>
						<div className='hidden md:flex w-full flex-col items-center justify-center mt-[60px] md:mb-0 mb-20'>
							<div className='h-[240px] w-[240px] flex items-center justify-center bg-white'>
								<img src='/assets/images/qr-code.svg' alt='' />
							</div>
							<div className='flex w-full items-center justify-center mt-6'>
								<MobileIcon />
								<p className='ml-2'>Scan to continue by phone</p>
							</div>
						</div>
					</div>
					<div className='md:w-3/5 flex flex-col gap-y-2 mt-12 md:mt-0'>
						{LANGUAGES.map((language) => (
							<div
								key={language.code}
								onClick={() => onSelectLanguage(language.code)}
							>
								<IconLinkButton
									name={language.name}
									image={language.image}
									url={{
										pathname: "/login",
										state: {
											image: language.image,
										},
									}}
								/>
							</div>
						))}
					</div>
				</div>
			</div>
		</AuthLayout>
	);
};

export default LanguageSelection;
