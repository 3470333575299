import React, { FC, ReactNode } from "react";

interface FormCheckboxProps {
  label: ReactNode | string;
  checked?: boolean;
  disabled?: boolean;
  id?: string;
  onChange?: (checked: boolean) => void;
}

const FormCheckbox: FC<FormCheckboxProps> = ({
  label,
  checked,
  disabled = false,
  id = '',
  onChange = () => {},
}) => {
  return (
    <div className="form-check flex items-center">
      <input
        className="
          rounded
          border-gray-300
          text-indigo-600
          shadow-sm
          focus:border-indigo-300
          focus:ring
          focus:ring-offset-0
          focus:ring-indigo-200
          focus:ring-opacity-50
          mr-2
          bg-blue-100
          border border-secondary
          w-[20px]
          h-[20px]
        "
        id={id}
        type="checkbox"
        checked={checked}
        disabled={disabled}
        onChange={(e) => onChange(e.target.checked)}
      />
      <label
        className="form-check-label inline-block"
        htmlFor={id}
      >
        {label}
      </label>
    </div>
  );
};

export default FormCheckbox;
