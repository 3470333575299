import React, { FC } from "react";

interface SelectableOptionProps {
  label: string;
  selected: boolean;
  handleSelect: () => void;
}

const SelectableOption: FC<SelectableOptionProps> = ({
  label,
  selected,
  handleSelect,
}) => {
  return (
    <div
      className={`${selected ? "bg-blue-800" : "bg-white"} ${
        selected ? "text-white" : "text-blue"
      } border ${
        selected ? "border-blue-800" : "border-secondary"
      } px-5 py-3 flex items-center justify-center cursor-pointer rounded-full`}
      onClick={handleSelect}
    >
      <p className={`${selected ? 'text-white' : ''} font-semibold text-base`}>{label}</p>
    </div>
  );
};

export default SelectableOption;
