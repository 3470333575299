import { HttpService } from "./http.service";
import {
  ICustomerRegisterForm,
  IResendVerificationRequest,
  IVatNumberInfo,
  IVerifyCodeForm
} from "../resources/interfaces";
import { CustomerModel } from "../resources/models";
import { trimObject } from "../utils/helpers";

export class AuthService {
  static registerCustomer(data: ICustomerRegisterForm, showSpinner = true) {
    return HttpService.post('/auth/register/customer', data, showSpinner);
  }

  static loginCustomerViaPhone(phone: string, showSpinner = true) {
    return HttpService.post('/auth/login/customer/phone', { phone }, showSpinner);
  }

  static loginCustomerViaEmail(email: string, showSpinner = true) {
    return HttpService.post('/auth/login/customer/email', { email }, showSpinner);
  }

  static resendVerificationCode(data: IResendVerificationRequest, showSpinner = true) {
    return HttpService.post('/auth/resend-verification-code', trimObject(data), showSpinner);
  }

  static verifyCode(data: IVerifyCodeForm, showSpinner = true) {
    return HttpService.post('/auth/verify-code', trimObject(data), showSpinner);
  }

  static getProfile(showSpinner = true): Promise<CustomerModel> {
    return HttpService.get('/auth/me', {}, showSpinner)
      .then((result) => new CustomerModel(result));
  }

  static validateVatNumber(vatNumber: string, showSpinner = true): Promise<IVatNumberInfo> {
    return HttpService.post('/auth/verify-vat-number', { vatNumber }, showSpinner);
  }
}
