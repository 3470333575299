import React, { FC } from "react";
import { Link, useHistory } from "react-router-dom";
import { useTimer } from "react-timer-hook";
import { useTranslation } from "react-i18next";
import { HomeIcon, WhiteTickIcon } from "../../icons";

interface AuthSuccessProps {
	title: string;
	subtitle: string;
}

const AuthSuccess: FC<AuthSuccessProps> = ({
	title,
	subtitle,
}) => {
	const { t } = useTranslation();
	const history = useHistory();

	const endTime = new Date(new Date().getTime() + 15000);
	const { seconds } = useTimer({
		expiryTimestamp: endTime,
		onExpire: () => {
			history.replace('/');
		},
	});

	return (
		<div className='min-h-[calc(100vh-80px)] w-full flex flex-col items-center'>
			<div className='w-[170px] h-[170px] rounded-full bg-[#6DA544] mb-12 flex items-center justify-center lg:mt-[200px] mt-[50px]'>
				<WhiteTickIcon />
			</div>
			<h1 className='font-bold mb-6 lg:text-5xl text-3xl text-center'>{title}</h1>
			<p className='text-xl max-w-[630px] text-center mb-8'>{subtitle}</p>
			<div className='py-4 px-8 mt-auto rounded-full border border-secondary max-w-[594px] w-full lg:w-auto flex items-center mb-10'>
				<Link
					to='/'
					className='h-[48px] w-[48px] bg-white border border-secondary rounded-full flex items-center justify-center cursor-pointer mr-5'
				>
					<HomeIcon />
				</Link>
				<p className='truncate w-[80%]'>
					{t('This page will be redirected automatically within')}
				</p>
				<p className='ml-auto whitespace-nowrap'>{seconds} sec</p>
			</div>
		</div>
	);
};

export default AuthSuccess;
