export const LANGUAGES = [
	{
		name: "Nederlands",
		code: "nl",
		image: "/assets/images/flags/nl.svg",
	},
	{
		name: "Français",
		code: "fr",
		image: "/assets/images/flags/fr.svg",
	},
	{
		name: "English",
		code: "en",
		image: "/assets/images/flags/en.svg",
	},
	{
		name: "Deutsch",
		code: "de",
		image: "/assets/images/flags/de.svg",
	},
	{
		name: "Italiano",
		code: "it",
		image: "/assets/images/flags/it.svg",
	},
];
