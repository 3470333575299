import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import classNames from "classnames";
import { ErrorMessage, Field, Form, Formik } from "formik";
import Button from "../../../components/common/Button";
import FormLabel from "../../../components/common/FormLabel";
import AuthLayout from "../../../layouts/AuthLayout";
import AuthLayoutHeader from "../../../layouts/AuthLayout/Header";
import { AuthService, ToastService } from "../../../services";

const LoginViaEmail: FC = () => {
	const { t } = useTranslation();
	const history = useHistory();

	const validationError = {
		required: t('This field is required'),
		invalidEmail: t('Please input valid email')
	};

	const formSchema = Yup.object().shape({
		email: Yup.string().email(validationError.invalidEmail)
			.typeError(validationError.invalidEmail)
			.required(validationError.required),
	});

	const initialFormValues = {
		email: '',
	};

	const onSubmit = (form: { email: string }) => {
		AuthService.loginCustomerViaEmail(form.email).then(() => {
			history.push(`/verify-code?email=${form.email}`);
		}).catch((err) => {
			ToastService.showHttpError(err, t('Login failed') as string);
		});
	};

	return (
		<AuthLayout hasPadding={true}>
			<div className='mb-7'>
				<AuthLayoutHeader title={t('Login')} />
			</div>

			<Formik
				initialValues={initialFormValues}
				validationSchema={formSchema}
				onSubmit={onSubmit}
			>
				{(formik) => {
					const { errors, touched, isValid, dirty } = formik;

					return (
						<Form className="formik-form max-w-[670px] m-auto">
							<div className='bg-white rounded px-6 py-5 lg:px-16 lg:py-10'>
								<div className={classNames(
									'form-field full-width',
									errors.email && touched.email && 'is-invalid',
								)}>
									<FormLabel label={t('What is your email?')} />
									<Field id="email" name="email" />
									<ErrorMessage name="email" component="div" className="invalid-feedback" />
								</div>
								<div className='flex items-center justify-end mt-8'>
									<Button
										type="submit"
										theme="primary"
										rounded="full"
										disabled={!dirty || !isValid}
									>
										{t('Send verification code')}
									</Button>
								</div>
							</div>
						</Form>
					);
				}}
			</Formik>
		</AuthLayout>
	);
};

export default LoginViaEmail;
