import React, { FC } from "react";

export const WhiteTickIcon: FC = () => {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_21_1329)">
        <path
          d="M30.0002 54.0013L16.0002 40.0013L11.3335 44.668L30.0002 63.3346L70.0002 23.3346L65.3335 18.668L30.0002 54.0013Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_21_1329">
          <rect width="80" height="80" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
