import React, { useState, useEffect, useMemo } from "react";
import { getCountryCallingCode, getCountries } from "react-phone-number-input";
import { CountryCode } from "libphonenumber-js/types";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../redux/hooks";
import en from "react-phone-number-input/locale/en.json";
import de from "react-phone-number-input/locale/de.json";
import fr from "react-phone-number-input/locale/fr.json";
import it from "react-phone-number-input/locale/it.json";
import nl from "react-phone-number-input/locale/nl.json";
import ReactCountryFlag from "react-country-flag";

const localeLabels: { [lang: string]: { [key: string]: string } } = {
  en,
  de,
  fr,
  it,
  nl,
};

// Define an interface for the props the component accepts
interface CountrySelectProps {
  type: "country" | "code";
  country?: string;
  disabled?: boolean;
  onChange(value: {
    countryCode: CountryCode;
    regionCode: string;
    countryName: string;
  }): void;
}

// Define an interface for the country option structure
interface CountryOption {
  code: CountryCode;
  name: string;
  group: string;
}

const CountrySelect: React.FC<CountrySelectProps> = ({
  type,
  country = "BE",
  disabled,
  onChange,
}) => {
  const { locale } = useAppSelector((state) => state.locale);
  const { t } = useTranslation();
  const defaultCountry = country as CountryCode;
  const [selectedCountry, setSelectedCountry] =
    useState<CountryCode>(defaultCountry);

  // Memoized labels and country options for performance optimization
  const labels = useMemo(() => localeLabels[locale] || en, [locale]);
  const countryOptions = useMemo(() => {
    const determineGroup = (code: CountryCode): string => {
      const NEIGHBORING_COUNTRIES = ["BE", "NL", "LU", "FR", "DE"];
      const EU_COUNTRIES = [
        "AT",
        "BE",
        "BG",
        "CY",
        "CZ",
        "DE",
        "DK",
        "EE",
        "ES",
        "FI",
        "FR",
        "GR",
        "HR",
        "HU",
        "IE",
        "IT",
        "LT",
        "LU",
        "LV",
        "MT",
        "NL",
        "PL",
        "PT",
        "RO",
        "SE",
        "SI",
        "SK",
      ];
      if (NEIGHBORING_COUNTRIES.includes(code)) return t("groups.Neighboring");
      if (EU_COUNTRIES.includes(code)) return t("groups.EU");
      return t("groups.NonEU");
    };

    return getCountries().map((code) => ({
      code: code as CountryCode,
      name: labels[code],
      group: determineGroup(code),
    }));
  }, [labels, t]);

  // Update component state and call onChange prop when the selected country changes
  useEffect(() => {
    const regionCode = getCountryCallingCode(selectedCountry);
    const countryName = labels[selectedCountry];
    onChange({
      countryCode: selectedCountry,
      regionCode,
      countryName,
    });
  }, [selectedCountry, labels]);

  return (
    <div>
      <div className="country-select relative w-full h-[48px] flex items-center bg-blue-100 border border-secondary rounded outline-none">
        <div className="PhoneInput">
          <div className="PhoneInputCountry">
            <select
              value={selectedCountry}
              onChange={(e) =>
                setSelectedCountry(e.target.value as CountryCode)
              }
              disabled={disabled}
              className="PhoneInputCountrySelect"
            >
              <optgroup label={t("groups.Neighboring") as string}>
                {countryOptions
                  .filter((option) => option.group === t("groups.Neighboring"))
                  .map((option) => (
                    <option key={option.code} value={option.code}>
                      {option.name}
                    </option>
                  ))}
              </optgroup>
              <optgroup label={t("groups.EU") as string}>
                {countryOptions
                  .filter((option) => option.group === t("groups.EU"))
                  .map((option) => (
                    <option key={option.code} value={option.code}>
                      {option.name}
                    </option>
                  ))}
              </optgroup>
              <optgroup label={t("groups.NonEU") as string}>
                {countryOptions
                  .filter((option) => option.group === t("groups.NonEU"))
                  .map((option) => (
                    <option key={option.code} value={option.code}>
                      {option.name}
                    </option>
                  ))}
              </optgroup>
            </select>
            <p className="flex gap-2 items-center">
              <ReactCountryFlag
                countryCode={selectedCountry}
                style={{
                  fontSize: "1.5em",
                  lineHeight: "1.5em",
                }}
              />

              {type === "country"
                ? labels[selectedCountry]
                : `+${getCountryCallingCode(selectedCountry)}`}
            </p>
          </div>
        </div>
        <div className="pointer-events-none absolute inset-y-0 right-2 flex items-center px-2">
          <svg
            className="fill-current h-5 w-5 text-green-600"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="white"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_536_179)">
              <path d="M7 10L12 15L17 10L7 10Z" fill="#2165B7" />
            </g>
            <defs>
              <clipPath id="clip0_536_179">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
    </div>
  );
};

export default CountrySelect;
