import React from "react";
import { BounceLoader } from "react-spinners";
import { useAppSelector } from "../../redux/hooks";

const SpinnerContainer = () => {
  const { loadingCount } = useAppSelector((state) => state.spinner);
  if (loadingCount <= 0) {
    return null;
  }

  return (
    <div className="fixed top-0 left-0 z-max w-screen h-screen flex-center bg-black bg-opacity-40">
      <BounceLoader color="#193451" size={60} />
    </div>
  );
};

export default SpinnerContainer;
