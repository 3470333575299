import i18next, {InitOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import enTranslation from './en/translation.json';
import deTranslation from './de/translation.json';
import frTranslation from './fr/translation.json';
import itTranslation from './it/translation.json';
import nlTranslation from './nl/translation.json';

export const resources = {
  en: {
    translation: enTranslation,
  },
  de: {
    translation: deTranslation,
  },
  fr: {
    translation: frTranslation,
  },
  it: {
    translation: itTranslation,
  },
  nl: {
    translation: nlTranslation,
  },
};

i18next
  // Enable automatic language detection
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // Standard language used
    fallbackLng: 'en',
    debug: true,
    resources,
    //Detects and caches a cookie from the language provided
    detection: {
      cache: ['localStorage']
    },
    interpolation: {
      escapeValue: false
    }
  } as InitOptions);

export default i18next;
